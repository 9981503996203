import React,{forwardRef} from 'react';
import { useRecoilState } from "recoil";
import {themeMode} from "recoil/atoms";
import Eyeopen from "assets/eye.svg";
import  EyeClose from "assets/eye-off.svg"

const InputFieldNDL = forwardRef(({children,...props},inputRef)=>{ 
    const [curTheme]=useRecoilState(themeMode)
    let DisableCss = props.disabled ? 'text-stone-300':'text-primary-text'
    let AdortmentCss = props.startAdornment ? 'pl-10' : ''
    var bordercss = props.error ?
        `bg-secondary-bg border font-inter border-error-text text-sm rounded-[4px] focus:ring-error-text dark:bg-secondary-bg focus:border-error-text block w-full ${AdortmentCss}  p-2.5 dark:text-error-text dark:placeholder-error-text dark:border-error-text h-10` 
        :
        `bg-secondary-bg border font-inter border-secondary-bg ${DisableCss}  text-sm rounded-[4px] focus:ring-interact-accent-default focus:border-interact-accent-default block w-full ${AdortmentCss} p-2.5  dark:bg-secondary-bg dark:border-secondary-bg dark:placeholder-field-02 dark:text-seondary-text dark:focus:ring-interact-accent-default dark:focus:border-interact-accent-default h-10`

    var textareacss = "block p-2.5 w-full font-inter h-[120px] text-sm text-primary-text bg-secondary-bg rounded-[4px] border border-secondary-bg focus:ring-interact-accent-default focus:border-interact-accent-default dark:bg-secondary-bg dark:border-secondary-bg dark:placeholder-field-02 dark:text-primary-text dark:focus:ring-interact-accent-default dark:focus:border-interact-accent-default"

    var helpertextcss = props.error ? "mt-2  font-inter text-sm text-error-text dark:text-error-text leading-[18px]" : "mt-2 font-inter text-sm text-primary-text dark:text-primary-text leading-[18px]"
    var labelcss = "block mb-1 text-[14px] font-inter leading-[18px] font-medium text-primary-text dark:text-primary-text"

    function SingleInput(){
        if(props.type === "file") {
            return <input class={`block w-full text-sm ${DisableCss}  border font-inter border-secondary-bg rounded-lg cursor-pointer bg-secondary-bg dark:text-primary-text focus:outline-none dark:bg-secondary-bg dark:border-secondary-bg dark:placeholder-field-02`}
                aria-describedby="file_input_help" id={props.id} type={props.type} onChange={props.onChange}
                ref={props.inputRef}>
            </input>
        }else{
            return  ( 
            <React.Fragment>
            <input
                type={props.type ? props.type : "text"}
                id={props.id}
                class={bordercss}
                placeholder={props.placeholder}
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                onChange={props.onChange}
                onClick={props.onClick}
                onBlur={handleBlur}
                ref={props.inputRef}
                defaultValue={props.defaultValue}
                style={props.style}
                onKeyPress={props.onKeyPress}
                // onKeyDown={props.onKeyDown}
                // autoFocus={props.autoFocus ? true : false}
                // autoComplete={props.autoComplete ? "off":""}
                onWheel={props.onWheel}
                maxLength={props.maxLength}
                onKeyDown={props.type === "number" ? handleNumberInputKeyDown : props.onKeyDown} // Handle number input key down event
                autoFocus={props.autoFocus ? true : false}
                autoComplete={props.autoComplete ? "off" : ""}    
                onFocus={props.onFocus}          
            />
            {
                props.eyeToggle &&   
                <button
              type="button"
            //   className="password-toggle-icon"
              onClick={props.togglePasswordVisibility}
            >
              <img src={props.showPassword ? EyeClose : Eyeopen} alt="Toggle Password Visibility" />
            </button>
            }
            </React.Fragment>
        )
        }
                        
                        
    }

    const handleNumberInputKeyDown = (event) => {
        // Prevent the default behavior for arrow keys (up and down)
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            event.preventDefault();
        }

        if (props.NoMinus && (event.key === "-" || event.key === "_")) {
            event.preventDefault();
        }
    };

    const handleNumberInputWheel = (event) => {
        event.preventDefault();
    };

    const handleBlur = (event) => {
        if (props.NoMinus && event.target.value < 0) {
            event.target.value = 0;
            props.onChange && props.onChange(event);
        }
        props.onBlur && props.onBlur(event);
    };
    return (
        <React.Fragment>
            <div style={{backgroundColor:curTheme==='dark'?"#282929":"#ffff",borderColor:"#282929"}}>
            {props.label && 
            <label class={labelcss} style={{color:curTheme==='dark'?'#ffff':'#000000'}}>{props.label}{props.mandatory && <span style={{ color: 'red' }}>*</span>}</label>}

            <div className='relative'>
                {props.startAdornment &&
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        {props.startAdornment}
                    </div>
                }
                {props.multiline ?
                    <textarea id={props.id} rows={props.maxRows}
                        class={textareacss} placeholder={props.placeholder}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                        name={props.label}
                        disabled={props.disabled}
                        ref={props.inputRef} onBlur={props.onBlur} >
                    </textarea> 
                    :
                    SingleInput()

                }
                {props.helperText &&
                <p class={helpertextcss} >{props.helperText}</p>}
                {props.endAdornment &&
                    <button type="button" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        {props.endAdornment}
                    </button>
                }
            </div>
            </div>
        </React.Fragment>

    )
})
const isNotRender = (prev, next) => {
    return prev.value !== next.value || prev.type !== next.type || prev.disabled !== next.disabled ||
      prev.error !== next.error || prev.helperText !== next.helperText || prev.defaultValue !== next.defaultValue
      || prev.endAdornment !== next.endAdornment || prev.dynamic !== next.dynamic ? false : true
  }
  const CustomTextField = React.memo(InputFieldNDL, isNotRender)
  export default CustomTextField;