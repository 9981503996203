import React from 'react';
import { ReactComponent as analytics_light } from 'assets/neo_icons/Dashboard/Analytics_Light.svg'  
import { ReactComponent as analytics_dark } from 'assets/neo_icons/Dashboard/Analytics_Dark.svg'
import { ReactComponent as dash_light } from 'assets/neo_icons/Dashboard/dashboard.svg'
import { ReactComponent as dash_dark } from 'assets/neo_icons/Dashboard/dashboard_dark.svg'
import { ReactComponent as explore_light } from 'assets/neo_icons/Dashboard/explore.svg'
import { ReactComponent as explore_dark } from 'assets/neo_icons/Dashboard/explore_dark.svg'
import { ReactComponent as reports_light } from 'assets/neo_icons/Dashboard/reports.svg'
import { ReactComponent as reports_dark } from 'assets/neo_icons/Dashboard/reports_dark.svg'
import { ReactComponent as dash_lightActive } from 'assets/neo_icons/Dashboard/Dashboard_lightActive.svg'
import { ReactComponent as dash_darkActive } from 'assets/neo_icons/Dashboard/Dashboard_darkActive.svg'
import { ReactComponent as explore_lightActive } from 'assets/neo_icons/Dashboard/Explore_lightActive.svg'
import { ReactComponent as explore_darkActive } from 'assets/neo_icons/Dashboard/Explore_darkActive.svg'
import { ReactComponent as forms_lightActive } from 'assets/neo_icons/Dashboard/Form_lightActive.svg'
import { ReactComponent as forms_darkActive } from 'assets/neo_icons/Dashboard/Form_darkActive.svg'
import { ReactComponent as reports_lightActive } from 'assets/neo_icons/Dashboard/Report_lightActive.svg'
import { ReactComponent as reports_darkActive } from 'assets/neo_icons/Dashboard/Report_darkActive.svg'
import { ReactComponent as account_light } from 'assets/neo_icons/Menu/account.svg'
import { ReactComponent as account_dark } from 'assets/neo_icons/Menu/account_dark.svg'
import { ReactComponent as help_light } from 'assets/neo_icons/Menu/help.svg'
import { ReactComponent as help_dark } from 'assets/neo_icons/Menu/help_dark.svg'
import { ReactComponent as chat_support_light } from 'assets/neo_icons/Menu/chat_support.svg'
import { ReactComponent as chat_support_dark } from 'assets/neo_icons/Menu/chat_support_dark.svg'
import { ReactComponent as settings_light } from 'assets/neo_icons/Menu/settings.svg'
import { ReactComponent as settings_dark } from 'assets/neo_icons/Menu/settings_dark.svg'
import { ReactComponent as Production_light } from 'assets/neo_icons/Menu/Production_dark.svg'
import { ReactComponent as Production_dark } from 'assets/neo_icons/Menu/Production_light.svg'
import { ReactComponent as line_light } from 'assets/neo_icons/Dashboard/Line_light.svg'
import { ReactComponent as line_dark } from 'assets/neo_icons/Dashboard/Line_dark.svg'
import { ReactComponent as line_active } from 'assets/neo_icons/Dashboard/Line_active.svg'
import { ReactComponent as Sprint_History } from 'assets/Histrory.svg'
import { ReactComponent as Offline_instruments } from 'assets/neo_icons/Menu/Offline_Instruments.svg';
import { ReactComponent as Alarms_light } from 'assets/neo_icons/Dashboard/Alarm.svg';
import { ReactComponent as Tasks_light } from 'assets/neo_icons/Dashboard/Task.svg';
import { ReactComponent as Fault_Analysis } from 'assets/neo_icons/FaultAnalysis/FaultAnalysis.svg'
import { ReactComponent as Asset_Health } from 'assets/neo_icons/AssetHealth/AssetHealth.svg'
import { ReactComponent as Asset_HealthDark } from 'assets/neo_icons/AssetHealth/AssetHealth-Dark.svg'
import { ReactComponent as scada_light } from 'assets/neo_icons/Equipments/blower.svg'
import { ReactComponent as scada_dark } from 'assets/neo_icons/Equipments/blower_dark.svg'
const Dashboards = React.lazy(() => import("components/layouts/Dashboards/Dashboard"));
// const Scada = React.lazy(() => import("components/layouts/Scada/Scada"));
const Explore = React.lazy(() =>import("components/layouts/Explore/index"))
const Reports = React.lazy(() => import("components/layouts/Reports"));
const Analytics = React.lazy(() => import("components/layouts/Analytics/index.js"));
const Support = React.lazy(() => import("components/layouts/Support"));
const Settings = React.lazy(() => import("components/layouts/Settings/index.js"));
const RequestAccess = React.lazy(() => import("components/layouts/Profile/RequestAccess"));
const Activity = React.lazy(() => import("components/layouts/Profile/Activity"));
const Profile = React.lazy(() => import("components/layouts/Profile/ProfileSetting"));
const Line = React.lazy(() => import("components/layouts/Line/index"));
const OfflineDAQ = React.lazy(() => import("components/layouts/OfflineDAQ/index")); 
const Alarms = React.lazy(() => import("components/layouts/Alarms/index")); 
const Tasks = React.lazy(() => import("components/layouts/Tasks/NewTask")); 
const FaultAnalysis = React.lazy(() => import("components/layouts/FaultAnalysis/index"))
const AssetHealth = React.lazy(() => import("components/layouts/AssetHealth/index"))
const Production = React.lazy(() => import("components/layouts/Settings/Production/Production"));
const ReleaseNotes=React.lazy(() => import("components/layouts/Profile/ReleaseNotes/index"));

const Routes = {
  mainRoutes: [  
    {
      path: "/dashboard",
      name: "dashboard",
      iconLight: dash_light,
      iconDark: dash_dark,
      activeLight: dash_lightActive,
      activeDark: dash_darkActive,
      component: Dashboards,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    // {
    //   path: "/scada",
    //   name: "scada",
    //   iconLight: scada_light,
    //   iconDark: scada_dark,
    //   activeLight: dash_lightActive,
    //   activeDark: dash_darkActive,
    //   component: Scada,
    //   layout: "/neo",
    //   iconFlag: true,
    //   footer: false,
    //   line: "/:line"
    // },
    {
      path: "/explore",
      name: "explore",
      iconLight: explore_light,
      iconDark: explore_dark,
      activeLight: explore_lightActive,
      activeDark: explore_darkActive,
      component: Explore,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    
    {
      path: "/reports",
      name: "reports",
      iconLight: reports_light,
      iconDark: reports_dark,
      activeLight: reports_lightActive,
      activeDark: reports_darkActive,
      component: Reports,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    }, 
    {
      path: "/Alarms",
      name: "Alarms",
      iconLight: Alarms_light,
      iconDark: Alarms_light,
      activeLight: Alarms_light,
      activeDark: Alarms_light,
      component: Alarms,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/Tasks",
      name: "Tasks",
      iconLight: Tasks_light,
      iconDark: Tasks_light,
      activeLight: Tasks_light,
      activeDark: Tasks_light,
      component: Tasks,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/analytics",
      name: "analytics",
      iconLight: analytics_light,
      iconDark: analytics_dark,
      activeLight: analytics_light,
      activeDark: analytics_dark,
      component: Analytics,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/FaultAnalysis",
      name: "PdM",
      iconLight: Fault_Analysis,
      iconDark: Fault_Analysis,
      activeLight: Fault_Analysis,
      activeDark: Fault_Analysis,
      component: FaultAnalysis,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/AssetHealth",
      name: "AssetHealth",
      iconLight: Asset_Health,
      iconDark: Asset_HealthDark,
      activeLight: AssetHealth,
      activeDark: AssetHealth,
      component: AssetHealth,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    }, 
    {
      path: "/offline",
      name: 'offline',
      iconLight: Offline_instruments,
      iconDark: reports_dark,
      activeLight: reports_lightActive,
      activeDark: reports_darkActive,
      component: OfflineDAQ,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    // {
    //   path: "/line",
    //   name: "line",
    //   iconLight: line_light,
    //   iconDark: line_dark,
    //   activeLight: line_active,
    //   activeDark: line_active,
    //   component: Line,
    //   layout: "/neo",
    //   iconFlag: true,
    //   footer: false,
    //   line: "/:line"
    // },
    
    {
      path: "/profile",
      name: "profile",
      iconLight: account_light,
      iconDark: account_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Profile,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/access",
      name: "access",
      iconLight: help_light,
      iconDark: help_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: RequestAccess,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/changeLog",
      name: "ChangeLog",
      iconLight: Sprint_History,
      iconDark: Sprint_History,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: ReleaseNotes,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/activity",
      name: "Activity",
      iconLight: help_light,
      iconDark: help_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Activity,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/support",
      name: "support",
      iconLight: chat_support_light,
      iconDark: chat_support_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Support,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    }, 
   
   
   
    {
      path: "/production",
      name: "production",
      iconLight: Production_light,
      iconDark: Production_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Production,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    
    
    {
      path: "/settings",
      name: "settings",
      iconLight: settings_light,
      iconDark: settings_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Settings,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line"
    }
  ],
};

export default Routes;
