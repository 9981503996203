import React from "react";
import { useRecoilState } from "recoil";
import {themeMode} from "recoil/atoms"

export default function TypographyNDL(props) {
    const [curTheme]=useRecoilState(themeMode)
    let PercentColor = ""
    if (props.color === "#EE0E51") {
        PercentColor = "text-[#EE0E51]"
    }
    else if (props.color === "#5DE700") {
        PercentColor = "text-[#5DE700]"
    }
    else if (props.color === "#00CDDC") {
        PercentColor = "text-[#00CDDC]"
    }
    else if (props.color === "#FFFFFF") {
        PercentColor = "text-[#FFFFFF]"
    }
    else if (props.color === "#242424") {
        PercentColor = "text-[#242424]"
    }
    else if (props.color === "#898989") {
        PercentColor = "text-[#898989]"
    }else if(props.color === "#76A43D"){
        PercentColor = "text-[#76A43D]"
    }
    else if(props.color === "#DA1E28"){
        PercentColor = "text-[#DA1E28]"
    }
    else if(props.color === "#FF815A"){
        PercentColor = "text-[#FF815A]"
    }
    else if(props.color === "#525252"){
        PercentColor = "text-[#525252]"
    }
    else {
        PercentColor = `text-[${props.color}]`
    }


    // eslint-disable-next-line no-unused-vars
    const colors =()=>{
        if(props.color === "primary"){
            return "text-interact-accent-default"
        }else if (props.color === "secondary"){
            return "text-secondary-text"
        }else if(props.color === "tertiary"){
            return "text-tertiary-text"
        }else if (props.color === "danger"){
            return "text-error-text"
        }else if(PercentColor){
            return PercentColor
        }else{
            return "text-primary-text"
        }
    }  
    const variant =
    {

        BoldNormal: {
            // bold normal 400
            sm: `text-[14px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-[18px] font-normal font-inter my-0`,
            base: `text-[16px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-[22px] font-normal font-inter my-0`,
            lg: `text-[18px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-6 font-normal font-inter my-0`,
            xl: `text-[20px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-7 font-normal font-inter my-0`,
            twoxl: `text-[24px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-8 font-normal font-inter my-0`,
            threexl: `text-[28px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-9 font-normal font-inter my-0`,
            fourxl: `text-[32px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-10 font-normal font-inter my-0`,
            fivexl: `text-[36px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-[44px] font-normal font-inter my-0`,
            sixxl: `text-[42px] ${curTheme === 'dark' ? 'text-white' :  colors() } leading-[50px] font-normal font-inter my-0`, 
            // sevenxl: `${props.color ? colors() :"text-primary-text"} text-7xl font-normal dark:text-white`,    // px      
            // eightxl: `${props.color ? colors() :"text-primary-text"} text-8xl font-normal dark:text-white`,    // px      
            // ninexl: `${props.color ? colors() :"text-primary-text"} text-9xl font-normal dark:text-white`   // px  
            
        },
        // bold medium 500
        BoldMedium: {
            sm: `text-[14px] ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-[18px] font-inter my-0`,
            base: `text-[16px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-[22px] font-inter my-0`,
            lg: `text-[18px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-6 font-inter my-0`,
            xl: `text-[20px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-7 font-inter my-0`,
            twoxl: `text-[24px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-8 font-inter my-0`,
            threexl: `text-[28px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-9 font-inter my-0`,
            fourxl: `text-[32px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-10 font-inter my-0`,
            fivexl: `text-[36px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-[44px] font-inter my-0`,
            sixxl: `text-[42px]  ${curTheme === 'dark' ? 'text-white' :  colors() } font-medium leading-[50px] font-inter my-0 `,
            // sevenxl: `${props.color ? colors() :"text-primary-text"} text-7xl font-medium ${modelText} dark:text-white`,    // px      
            // eightxl: `${props.color ? colors() :"text-primary-text"} text-8xl font-medium ${modelText} dark:text-white`,    // px      
            // ninexl: `${props.color ? colors() :"text-primary-text"} text-9xl font-medium ${modelText} dark:text-white`   // px   
            
        },
        // SEMI BOLD 600  
        semiBold: { 
            sm: `text-[14px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-5 font-inter my-0`, // 14px      
            base: `text-[16px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-6 font-inter my-0`, // 16px      
            lg: `text-[18px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-7 font-inter my-0`, // 18px      
            xl: `text-[20px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-7 font-inter my-0`, // 20px      
            twoxl: `text-[24px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-8 font-inter my-0`, // 24px      
            threexl: `text-[28px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-9 font-inter my-0`, // 30px      
            fourxl: `text-[32px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-10 font-inter my-0`, // 36px      
            fivexl: `text-[36px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-[44px] font-inter my-0`, // 48px      
            sixxl: `text-[42px]  ${curTheme === 'dark' ? 'text-white' :  colors()} font-semibold leading-[50px] font-inter my-0`, // 60px      
            // sevenxl: `${props.color ? colors :"text-primary-text"} text-7xl font-semibold dark:text-white leading-5`,    // px      
            // eightxl: `${props.color ? colors :"text-primary-text"} text-8xl font-semibold dark:text-white`,    // px      
            // ninexl: `${props.color ? colors :"text-primary-text"} text-9xl font-semibold dark:text-white`    // px   
            

        }


    }
    const renderStyleClass = () =>{
        if(props.variant === "4xl-body-02"){
            return variant.BoldMedium.fourxl
        }else if(props.variant === "4xl-body-01"){
            return variant.BoldNormal.fourxl
        }else if (props.variant === "3xl-body-02"){
            return  variant.BoldMedium.threexl
        }else if (props.variant === "3xl-heading-02"){
            return  variant.semiBold.threexl
        }else if (props.variant === "2xl-body-02"){
            return variant.BoldMedium.twoxl
        }else if (props.variant === "xl-body-02"){
            return variant.BoldMedium.xl
        }else if (props.variant === "sm-body-02"){
            return variant.BoldMedium.sm
        }else if (props.variant === "base-heading-02"){
            return variant.semiBold.base
        }else if (props.variant === "5xl-heading-02"){
            return  variant.semiBold.fivexl
        }else if (props.variant === "base-body-02"){
            return variant.BoldMedium.base
        }else if (props.variant === "base-body-01"){
            return variant.BoldNormal.base
        }else if (props.variant === "lg-heading-02"){
            return  variant.semiBold.lg
        }else if (props.variant === "xl-heading-02"){
            return variant.semiBold.xl
        }else if (props.variant === "2xl-heading-02"){
            return  variant.semiBold.twoxl
        }else if ( props.variant === "4xl-heading-02"){
            return  variant.semiBold.fourxl
        }else if (props.variant === "sm-body-01"){
            return variant.BoldNormal.sm
        }else if (props.variant === "2xl-body-01"){
            return variant.BoldNormal.twoxl
        }else if (props.variant === "sm-label-text-01"){
            return variant.BoldMedium.sm
        }else if (props.variant === "sm-helper-text-01"){
            return variant.BoldNormal.sm
        }else if (props.variant === "lg-body-02"){
            return  variant.BoldMedium.lg
        }else if (props.variant === "lg-body-01"){
            return variant.BoldNormal.lg
        }else if (props.variant === "xl-body-01"){
            return variant.BoldNormal.xl
        }else if (props.variant === "3xl-body-01"){
            return variant.BoldNormal.threexl
        }else if (props.variant === "5xl-body-01"){
            return variant.BoldNormal.fivexl
        }else if (props.variant === "5xl-body-02"){
            return variant.BoldMedium.fivexl
        }else if (props.variant === "6xl-heading-02"){
            return  variant.semiBold.sixxl
        }else if ( props.variant === "6xl-body-01"){
            return variant.BoldNormal.sixxl
        }else if ( props.variant === "6xl-body-02"){
            return variant.BoldMedium.sixxl
        }else if ( props.variant === "sm-heading-02"){
            return variant.semiBold.sm
        }else {
            return variant.BoldMedium.sm
        }
    }
    return (

        <p
            class={
                [renderStyleClass(),props.class].join(" ")
            }
            id={props.id}
            style={props.style}
            onClick={props.onClick}
        >{props.value ? props.value : props.children}</p>
    )

}