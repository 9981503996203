import React from 'react';
import { ReactComponent as analytics_light } from 'assets/neo_icons/Dashboard/Analytics_Light.svg'  
import { ReactComponent as analytics_dark } from 'assets/neo_icons/Dashboard/Analytics_Dark.svg'
import { ReactComponent as dash_light } from 'assets/neo_icons/Dashboard/dashboard.svg'
import { ReactComponent as dash_dark } from 'assets/neo_icons/Dashboard/dashboard_dark.svg'
import { ReactComponent as explore_light } from 'assets/neo_icons/Dashboard/explore.svg'
import { ReactComponent as explore_dark } from 'assets/neo_icons/Dashboard/explore_dark.svg'
import { ReactComponent as reports_light } from 'assets/neo_icons/Dashboard/reports.svg'
import { ReactComponent as reports_dark } from 'assets/neo_icons/Dashboard/reports_dark.svg'
import { ReactComponent as dash_lightActive } from 'assets/neo_icons/Dashboard/Dashboard_lightActive.svg'
import { ReactComponent as dash_darkActive } from 'assets/neo_icons/Dashboard/Dashboard_darkActive.svg'
import { ReactComponent as explore_lightActive } from 'assets/neo_icons/Dashboard/Explore_lightActive.svg'
import { ReactComponent as explore_darkActive } from 'assets/neo_icons/Dashboard/Explore_darkActive.svg'
import { ReactComponent as forms_lightActive } from 'assets/neo_icons/Dashboard/Form_lightActive.svg'
import { ReactComponent as forms_darkActive } from 'assets/neo_icons/Dashboard/Form_darkActive.svg'
import { ReactComponent as reports_lightActive } from 'assets/neo_icons/Dashboard/Report_lightActive.svg'
import { ReactComponent as reports_darkActive } from 'assets/neo_icons/Dashboard/Report_darkActive.svg'
import { ReactComponent as account_light } from 'assets/neo_icons/Menu/account.svg'
import { ReactComponent as account_dark } from 'assets/neo_icons/Menu/account_dark.svg'
import { ReactComponent as help_light } from 'assets/neo_icons/Menu/help.svg'
import { ReactComponent as help_dark } from 'assets/neo_icons/Menu/help_dark.svg'
import { ReactComponent as chat_support_light } from 'assets/neo_icons/Menu/chat_support.svg'
import { ReactComponent as chat_support_dark } from 'assets/neo_icons/Menu/chat_support_dark.svg'
import { ReactComponent as Offline_instruments } from 'assets/neo_icons/Menu/Offline_Instruments.svg';
import { ReactComponent as Alarms_light } from 'assets/neo_icons/Dashboard/Alarm.svg';
import { ReactComponent as Tasks_light } from 'assets/neo_icons/Dashboard/Task.svg';
import { ReactComponent as Fault_Analysis } from 'assets/neo_icons/FaultAnalysis/FaultAnalysis.svg';
import { ReactComponent as Sprint_History } from 'assets/Histrory.svg'
import { ReactComponent as scada_light } from 'assets/neo_icons/Equipments/blower.svg'
import { ReactComponent as scada_dark } from 'assets/neo_icons/Equipments/blower_dark.svg'
const Dashboards = React.lazy(() => import("components/layouts/Dashboards/Dashboard"));
const Explore = React.lazy(() =>import("components/layouts/Explore/index"))
const Reports = React.lazy(() => import("components/layouts/Reports"));
const Analytics = React.lazy(() => import("components/layouts/Analytics/index.js"));
const Support = React.lazy(() => import("components/layouts/Support"));
const RequestAccess = React.lazy(() => import("components/layouts/Profile/RequestAccess"));
const Activity = React.lazy(() => import("components/layouts/Profile/Activity"));
const Profile = React.lazy(() => import("components/layouts/Profile/ProfileSetting"));
const OfflineDAQ = React.lazy(() => import("components/layouts/OfflineDAQ/OfflineDAQ"));
const Alarms = React.lazy(() => import("components/layouts/Alarms/index")); 
const Tasks = React.lazy(() => import("components/layouts/Tasks/NewTask")); 
const FaultAnalysis = React.lazy(() => import("components/layouts/FaultAnalysis/index"));
const ReleaseNotes=React.lazy(() => import("components/layouts/Profile/ReleaseNotes/index"));
const Scada = React.lazy(() => import("components/layouts/Scada/Scada"));
const Routes_Users = {
  mainRoutes: [
    {
      path: "/dashboard",
      name: 'dashboard',
      iconLight: dash_light,
      iconDark: dash_dark,
      activeLight: dash_lightActive,
      activeDark: dash_darkActive,
      component: Dashboards,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/explore",
      name: 'explore',
      iconLight: explore_light,
      iconDark: explore_dark,
      activeLight: explore_lightActive,
      activeDark: explore_darkActive,
      component: Explore,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/reports",
      name: 'reports',
      iconLight: reports_light,
      iconDark: reports_dark,
      activeLight: reports_lightActive,
      activeDark: reports_darkActive,
      component: Reports,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/offline",
      name: 'Offline DAQ',
      iconLight: Offline_instruments,
      iconDark: reports_dark,
      activeLight: reports_lightActive,
      activeDark: reports_darkActive,
      component: OfflineDAQ,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    
    {
      path: "/profile",
      name: 'profile',
      iconLight: account_light,
      iconDark: account_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Profile,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/access",
      name: 'access',
      iconLight: help_light,
      iconDark: help_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: RequestAccess,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/activity",
      name: 'Activity',
      iconLight: help_light,
      iconDark: help_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Activity,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/changeLog",
      name: "ChangeLog",
      iconLight: Sprint_History,
      iconDark: Sprint_History,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: ReleaseNotes,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/analytics",
      name: 'Analytics',
      iconLight: analytics_light,
      iconDark: analytics_dark,
      activeLight: analytics_light,
      activeDark: analytics_dark,
      component: Analytics,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/support",
      name: 'support',
      iconLight: chat_support_light,
      iconDark: chat_support_dark,
      activeLight: forms_lightActive,
      activeDark: forms_darkActive,
      component: Support,
      layout: "/neo",
      iconFlag: false,
      footer: false,
      bottom: 0,
      line: "/:line"
    },
    {
      path: "/Alarms",
      name: "Alarms",
      iconLight: Alarms_light,
      iconDark: Alarms_light,
      activeLight: Alarms_light,
      activeDark: Alarms_light,
      component: Alarms,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/Tasks",
      name: "Tasks",
      iconLight: Tasks_light,
      iconDark: Tasks_light,
      activeLight: Tasks_light,
      activeDark: Tasks_light,
      component: Tasks,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/FaultAnalysis",
      name: "FaultAnalysis",
      iconLight: Fault_Analysis,
      iconDark: Fault_Analysis,
      activeLight: Fault_Analysis,
      activeDark: Fault_Analysis,
      component: FaultAnalysis,
      layout: "/neo",
      iconFlag: true,
      footer: false,
      line: "/:line"
    },
    {
      path: "/scada",
      name: "scada",
      iconLight: scada_light,
      iconDark: scada_dark,
      activeLight: dash_lightActive,
      activeDark: dash_darkActive,
      component: Scada,
      layout: "/neo",
      iconFlag: true,
      footer: false,
    }
  ]
};

export default Routes_Users;
