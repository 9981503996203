/* eslint-disable no-unused-vars */
import React, { useState } from "react"; 

import { Route, Routes, BrowserRouter,HashRouter, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import { useRecoilState } from "recoil";
import { user, userData, userDefaultLines, currentPage, decodeToken, currentUserRole, isLogin,LineHaveLicense } from "recoil/atoms";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "./components/Context";
import decode from "jwt-decode";
import LoadingScreenNDL from "./LoadingScreenNDL"
import configParam from "../src/config";
import gqlQueries from "./components/layouts/Queries"
import { useMutation } from "@apollo/client";
import Bowser from "bowser";
import { Helmet } from "react-helmet";
import routes from "routes.js";
import routes_users from "routes_users.js";
import Chatbot from 'components/layouts/ChatBot/components/ChatBot';


const NEO = React.lazy(() => import(/* webpackChunkName: "neoTemplate" */ /* webpackPreLoad: true */ /* webpackPrefetch: true */  'components/layouts/neoTemplate.js'));
const Login = React.lazy(() => import(/* webpackChunkName: "LoginView" */ /* webpackPreLoad: true */ /* webpackPrefetch: true */'components/layouts/LoginView'));
const RequestAcc = React.lazy(() => import(/* webpackChunkName: "LoginView" */ /* webpackPreLoad: true */ /* webpackPrefetch: true */'components/layouts/AccessRequest'));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "LoginView" */ /* webpackPreLoad: true */ /* webpackPrefetch: true */'components/layouts/Signin/ResetPassword'));
const RouteMissing = React.lazy(() => import(/* webpackChunkName: "RouteMissing" */ /* webpackPreLoad: true */ /* webpackPrefetch: true */'components/RouteMissing'));
const Explore = React.lazy(() => import("components/layouts/Explore/index"));
const Dashboards = React.lazy(() => import("components/layouts/Dashboards/Dashboard"));
const Tasks = React.lazy(() => import("components/layouts/Tasks/NewTask")); 
const Sample = React.lazy(() => import("./Samples"));
const OutofLicense = React.lazy(() => import("components/layouts/LicenseCard/OutofLicenseCard.js"));


 
export default function App() {
  
  const { t } = useTranslation();
  const [curPage] = useRecoilState(currentPage);
  const existingTokens = localStorage.getItem("neoToken");
  const [decoToken, setDecoToken] = useRecoilState(decodeToken);
  const [, setcurruser] = useRecoilState(user);
  const [, setuserDetails] = useRecoilState(userData);
  const [, setUserDefaultLines] = useRecoilState(userDefaultLines);
  const [userLogin, setUserLogin] = useRecoilState(isLogin);
  const [currUserRole] = useRecoilState(currentUserRole); 
  const [APP_type] = useState(process.env.REACT_APP_WEB || "web");
  
  // console.log("APP_type",APP_type,process.env.REACT_APP_WEB)
  const [authTokens, setAuthTokens] = React.useState(existingTokens);

  const setTokens = (data) => {
    setAuthTokens(data);
  };

  const [userAccess] = useMutation(
    configParam.addUserHistory,
    {
      update: (inMemoryCache, returnData) => {
      //  console.log(returnData,"returnData") P:136
      }
    }
  );
  React.useEffect(() => {
    if (existingTokens !== "" && existingTokens !== "undefined" && existingTokens !== null) {
      var decodedToken = decode(existingTokens, { complete: true });
      var dateNow = new Date();

      if (decodedToken.exp < parseInt(dateNow.getTime() / 1000)) {
        (async () => {
          await configParam.FETCH_REFRESH_TOKEN()
            .then((result) => {
              // console.log(result,decodedToken.exp,parseInt(dateNow.getTime() / 1000))
              setAuthTokens(result.result.access_token);
              localStorage.setItem("neoToken",result.result.access_token)
            })
            .catch(error => console.log("error", error))
        })();
      } else {
        setAuthTokens(existingTokens);
        setDecoToken({ ...decode(existingTokens) });

        configParam.RUN_GQL_API(gqlQueries.GetUserDefaults, { user_id: decode(existingTokens).app_user_id })
          .then((returnData) => {
            if (returnData && returnData.neo_skeleton_user && returnData.neo_skeleton_user.length !== 0) {
              setuserDetails(returnData.neo_skeleton_user[0]);
              setcurruser({ id: returnData.neo_skeleton_user[0].id, sgid: returnData.neo_skeleton_user[0].sgid ? returnData.neo_skeleton_user[0].sgid : "", name: returnData.neo_skeleton_user[0].name });
              setUserDefaultLines(returnData.neo_skeleton_user[0].user_role_lines);
              if (userLogin) {
                setUserLogin(false);
                const browser = Bowser.parse(window.navigator.userAgent);
                const info = {
                  browser_name: browser.browser.name,
                  browser_version: browser.browser.version,
                  os_name: browser.os.name,
                  os_version: browser.os.versionName,
                  device: browser.platform.type
                };
                userAccess({ variables: { user_id: returnData.neo_skeleton_user[0].id, info: info } });
              }
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingTokens, setDecoToken]);
   
  const switchRoutes = (
    <Route path="/neo" name="NEO - Dashboard" element={<PrivateRoute user={decoToken}><NEO /></PrivateRoute>}>
    
     {
      ((currUserRole.id === 3) ? routes_users : routes).mainRoutes.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.layout + route.path+route.line}
            exact={route.exact}
            name={route.name}
            element={<route.component />} />
        ) : (null);
      })}
      <Route path="/neo/dashboard/:line/:moduleName" element={<Dashboards/>}/> 
      <Route path="/neo/explore/:line/:moduleName" element={<Explore/>} />
      <Route path="/neo/Tasks/:line/:moduleName" element={<Tasks/>} />
    </Route>
  );
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, HF: { HMS: "HH:mm:ss", HMSS: "HH:mm:ss:SSS", HM: "HH:mm" } }}>
       {APP_type == 'portable' ? 
       <HashRouter>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{"Neo - " + t(curPage)}</title>
          </Helmet>
          <React.Suspense fallback={<LoadingScreenNDL />}>
          
            <Routes>
              
              {switchRoutes}
              <Route path='/request_access' element={<RequestAcc />} />
              <Route path='/resetpassword/:id' element={<ResetPassword />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/sample" exact element={<Sample />} />
              <Route path='/licencseExpired' exact element={<OutofLicense/>} />
              <Route path="/" exact element={<Navigate to="/login" />} />
              <Route path="*" element={<RouteMissing />} />
            </Routes>
            <Chatbot />
          </React.Suspense>
        </HashRouter> : 
        <BrowserRouter>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Neo - " + t(curPage)}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingScreenNDL />}>
          <Routes>
            
            {switchRoutes}
            <Route path='/request_access' element={<RequestAcc />} />
            <Route path='/resetpassword/:id' element={<ResetPassword />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/sample" exact element={<Sample />} />
            <Route path='/licencseExpired' exact element={<OutofLicense/>} />
            <Route path="/" exact element={<Navigate to="/login" />} />
            <Route path="*" element={<RouteMissing />} />
          </Routes>
          <Chatbot />
        </React.Suspense>
      </BrowserRouter>}

    </AuthContext.Provider>
  );
}
