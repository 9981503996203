import React from "react";

function AvatarNDL(props) {

    return (
        <React.Fragment>
            {props.src ? 
                <img class={props.className} style={props.style} src={props.src} alt={props.alt}>
                </img>
                :
                <div class={"relative inline-flex items-center justify-center overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 " +(props.className)+""}>
                    <span class="font-medium text-gray-600 dark:text-gray-300" style={props.style}>{props.initial}</span>
                </div>
            }

            
        </React.Fragment>
    );

}



export default AvatarNDL;